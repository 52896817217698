<template>
    <div class="position-absolute" v-if="kycStatus !== 3">
        <div @click="goToRoute" :class="[showComponent ? 'active' : '', kycStatus == 1 ? 'kyc-pending' : 'subheader-red']" class="subheader align-items-center justify-content-center">
            <template v-if="!kycStatus">
                <img class="me-2" :src="warningIcon" alt="">
                <p v-if="hasDeposited && !hasCompletedKyc">{{$t('NEED_TO_COMPLETE_KYC_TO_INVEST')}}</p>
            </template>
            <template v-else>
                <p>{{statusMessages[kycStatus]}}</p>
            </template>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import warningIcon from '../assets/images/icon-warning.svg';
import config from '../config';

export default defineComponent({
    name: 'Subheader',
    props: ['type'],
    data: function() {
        return {
            warningIcon: warningIcon,
            hasCompletedKyc: true,
            hasDeposited: true,
            showComponent: false,
            accessToken: null,
            kycStatus: null,
            statusMessages: {
                1: this.$t('KYC_PENDING'),
                2: this.$t('KYC_REJECTED'),
                4: this.$t('KYC_REJECTED_TEMP')
            }
        }
    },
    mounted() {
        this.hasDeposits = this.$store.state.hasMinDeposit;
        this.hasCompletedKyc = !!this.$store.state.userData?.completed_kyc;
        console.log(this.$store.state.hasMinDeposit, !!this.$store.state.userData?.completed_kyc)
        this.$store.watch(state => {
            this.accessToken = state.accessToken;
            if (state.accessToken) {
                this.getKYCStatus();
            }
            console.log(state.userData);
        });
    },
    methods: {
        getKYCStatus() {
            fetch(`${config.API}/api/v1/active-kyc-request`, {
                headers: {
                    ...config.defaultHeaders,
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data?.data?.active_kyc_request) {
                    this.$store.dispatch('setKycStatus', data.data.active_kyc_request.status);
                    data.data.active_kyc_request.comment && this.$store.dispatch('setKycComment', data.data.active_kyc_request.comment);
                    this.kycStatus = data?.data?.active_kyc_request.status;
                    if (!this.$props.type === 'settings') {
                        this.showComponent = false;
                    } else {
                        this.showComponent = true;
                    }
                } else {
                    this.$store.dispatch('setKycStatus', null);
                    this.showComponent = true;
                }
            })
        },
        goToRoute() {
            if (!this.hasDeposited) {
                this.$router.push('/investments');
            } else if (this.hasDeposited && !this.hasCompletedKyc) {
                this.$router.push({name: 'Settings', params: {goToKyc: true}});
            }
        }
    }
});
</script>
