<template>
  <!-- <Header type="regular"/> -->

  <!--  -->
  <Subheader type="settings" v-if="loggedIn && isActive === 't1'" />
  <div class="container" v-if="loggedIn === false">
    <div class="row mt-5 pt-5">
      <div class="col-12 not-loggedin">
        <h1 class="font-primary success-heading text-center w-100">
          {{ $t("NEED_TO_SIGN_IN") }}
        </h1>
        <p class="auth-explanation-text">{{ $t("SIGN_IN_CTA_TEXT") }}</p>
        <router-link to="/login" class="button">{{
          $t("SIGN_IN")
        }}</router-link>
        <router-link to="/register" class="mt-4">{{
          $t("REGISTER")
        }}</router-link>
      </div>
    </div>
  </div>
  <div
    v-else
    :class="!hasCompletedKyc || !hasDeposits ? 'mt-5' : ''"
    class="layout"
  >
    <div class="container py-5">
      <div class="row">
        <div class="tabs-wrapper">
          <div
            :class="isActive === 't1' ? 'active' : ''"
            @click="selectTab('t1')"
            class="tab"
          >
            <span>{{ $t("KYC") }}</span>
          </div>
          <div
            :class="isActive === 't2' ? 'active' : ''"
            @click="selectTab('t2')"
            class="tab mx-1"
          >
            <span>{{ $t("FINANCIAL") }}</span>
          </div>
          <div
            :class="isActive === 't3' ? 'active' : ''"
            @click="selectTab('t3')"
            class="tab"
          >
            <span>{{ $t("SECURITY") }}</span>
          </div>
        </div>
        <div class="d-flex no-gutters">
          <div
            class="d-flex w-100 align-items-center justify-content-center"
            v-if="kycLoading"
          >
            <div class="loader"></div>
          </div>
          <div v-else class="col-lg-12 w-100">
            <div
              v-if="isActive === 't1' && !showKycForm"
            >
              <h3 class="mb-4 text-center mt-5">{{$t('NEED_TO_COMPLETE_KYC')}}</h3>
              <p class="kyc-info-text">
                {{ $t("SETTINGS_KYC_EXPLANATION") }}
              </p>
              <button
                style="margin: 0 auto"
                @click="handleKycBtnClick"
                class="button"
              >
                {{ $t("START_KYC") }}
              </button>
            </div>
            <div
              v-if="showKycForm"
              :class="'tabContent' + (isActive == 't1' ? 'active' : '')"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                v-if="!iframeID && !user"
              >
                <div class="loader"></div>
              </div>
              <!-- <div v-if="iframeID">
                <iframe
                  :src="`https://go.test.idnow.de/${iframeID}`"
                  style="width: 100vw; height: 800px"
                  allow="camera ; microphone "
                  allowfullscreen=""
                  class="kyc-iframe mt-5"
                ></iframe>
              </div> -->
              <div
                class="
                  mt-4
                  kyc-form-wrapper
                  d-flex
                  flex-column
                  align-items-center
                "
                v-if="hasCompletedKyc || showKycForm"
              >
                <h3 class="mb-4 mt-4 text-center">{{ $t("KYC_INFORMATION") }}</h3>
                <p class="kyc-info-text" v-if="kycComment">
                  {{$t('ADMIN_COMMENT')}}: {{kycComment}}
                </p>
                <p v-else class="kyc-info-text">
                  {{ $t("KYC_INFO_TEXT") }}
                </p>
                <div v-if="iframeID"></div>
                <div class="erorrs-wrapper" v-if="kycErrors.length">
                  <p
                    :key="error"
                    v-for="error of kycErrors"
                    class="error-msg text-center"
                  >
                    {{ error }}
                  </p>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h4 class="mb-4 mt-2">{{$t('GENERAL_INFO')}}</h4>
                    <div class="form-group">
                      <label>{{ $t("FIRST_NAME") }}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        name="name"
                        v-model="kycForm.firstname"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.firstname && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_FIRST_NAME") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                    <label>{{ $t("LAST_NAME") }}*</label>
                    <input
                      :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                      class="customForm"
                      type="text"
                      name="last_name"
                      v-model="kycForm.lastname"
                    />
                    <p
                      class="validation-error"
                      v-if="!kycForm.lastname && validationCalled"
                    >
                      {{ $t("PLEASE_ENTER_LAST_NAME") }}
                    </p>
                  </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("EMAIL") }}*</label>
                      <input
                        :disabled="true"
                        class="customForm"
                        type="text"
                        name="last_name"
                        v-model="kycForm.email"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("ADDRESS") }}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        name="address"
                        v-model="kycForm.street"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.street && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_ADDRESS") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{$t('CITY')}}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        name="city"
                        v-model="kycForm.city"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.birth_place && validationCalled"
                      >
                        {{$t('PLEASE_ENTER_CITY')}}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("BIRTHPLACE") }}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        name="last_name"
                        v-model="kycForm.birth_place"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.birth_place && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_BIRTHPLACE") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("COUNTRY") }}*</label>
                      <select
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        id="country_id"
                        v-model="kycForm.country"
                        class="customForm"
                      >
                        <option selected>{{ $t("SELECT_COUNTRY") }}</option>
                        <template
                          v-for="(country, key) in countries"
                          v-bind:key="key"
                        >
                          <option :value="key">{{ country }}</option>
                        </template>
                      </select>
                      <p
                        class="validation-error"
                        v-if="!kycForm.country && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_COUNTRY") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("NATIONALITY") }}*</label>

                      <select
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        id="country_id"
                        v-model="kycForm.nationality"
                        class="customForm"
                      >
                        <option selected>{{ $t("SELECT_COUNTRY") }}</option>
                        <template
                          v-for="(country, key) in countries"
                          v-bind:key="key"
                        >
                          <option :value="key">{{ country }}</option>
                        </template>
                      </select>
                      <p
                        class="validation-error"
                        v-if="!kycForm.nationality && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_NATIONALITY") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("POSTAL_CODE") }}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        name="post_code"
                        v-model="kycForm.zipcode"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.zipcode && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_POSTAL_CODE") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("BIRTHDAY") }}*</label>
                      <datepicker
                        startingView="year"
                        class="datepicker-input mt-2 w-100"
                        v-model="kycForm.birthday"
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.birthday && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_BIRTHDAY") }}
                      </p>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>{{ $t("PHONE_CODE") }}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        v-model="kycForm.phone_code"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.phone_code && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_PHONE_CODE") }}
                      </p>
                      <p class="validation-error" v-if="invalidPhoneCode">
                        {{$t('INVALID_PHONE_CODE')}}
                      </p>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <label>{{ $t("MOBILE_PHONE_NUM") }}*</label>
                      <input
                        :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                        class="customForm"
                        type="text"
                        v-model="kycForm.mobilephone"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.mobilephone && validationCalled"
                      >
                        {{ $t("PLEASE_ENTER_MOBILE_PHONE_NUM") }}
                      </p>
                      <p class="validation-error" v-if="invalidPhone">
                        {{$t('PHONE_NUM_INVALID')}}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t("GENDER") }}*</label>
                      <select
                        :disabled="hasKyc"
                        class="w-100"
                        v-model="kycForm.gender"
                      >
                        <option selected class="d-none" value="">
                          {{ $t("SELECT_GENDER") }}
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      <p
                        class="validation-error"
                        v-if="!kycForm.gender && validationCalled"
                      >
                        {{ $t("PLEASE_SELECT_GENDER") }}
                      </p>
                    </div>
                  </div> -->
                </div>
                <template v-if="(kycStatus === null || kycStatus === 4) && !submitSuccess">
                  <h4 class="text-left w-100 mb-4 mt-5">{{$t('KYC_DOCUMENTS')}}</h4>
                  <div @click.stop.prevent class="form-group w-100">
                    <label>{{$t('DOCUMENT_FRONT')}}*</label>
                    <div class="dz-wrapper">
                      <form action="/" id="dropzone-1" class="dropzone"></form>
                    </div>
                    <p
                      class="validation-error"
                      v-if="!kycForm.documentFront && validationCalled"
                    >
                      {{ $t("PLEASE_ADD_DOCUMENT_FRONT") }}
                    </p>
                  </div>
                  <div class="form-group w-100">
                    <label>{{$t('DOCUMENT_BACK')}}*</label>
                    <div class="dz-wrapper">
                      <form action="/" id="dropzone-2" class="dropzone"></form>
                    </div>
                      <p
                        class="validation-error"
                        v-if="!kycForm.documentBack && validationCalled"
                      >
                        {{ $t("PLEASE_ADD_DOCUMENT_BACK") }}
                      </p>
                  </div>
                  <div class="form-group w-100">
                    <label>{{$t('SELFIE_IMAGE')}}*</label>
                    <div class="dz-wrapper">
                      <form action="/" id="dropzone-3" class="dropzone"></form>
                    </div>
                    <p
                      class="validation-error"
                      v-if="!kycForm.selfieImage && validationCalled"
                    >
                      {{ $t("PLEASE_ADD_SELFIE_IMAGE") }}
                    </p>
                  </div>
                </template>
                <div class="form-group mt-4 d-flex justify-content-center pt-2 w-100">
                  <button
                  :class="submitSuccess ? 'disabled' : ''"
                    v-if="!hasKyc"
                    type="button"
                    :disabled="kycStatus !== null && kycStatus !== 4 || submitSuccess"
                    class="button w-100"
                    @click="submitKyc"
                  >
                    {{ $t("SUBMIT") }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <div
              class="ps-0 ps-lg-5 ms-0 ms-lg-4 py-4"
              v-else-if="hasDeposits === false && isActive === 't1'"
            >
              <h3 class="mb-4">No deposits</h3>
              <p class="kyc-no-deposits">
                You haven't deposited anything yet. Please click
                <router-link to="/">here</router-link> to go to the wallet page
                and make a deposit
              </p>
            </div> -->
            <!-- <div
              class="col-lg-9"
              v-else-if="user && user.status === 2 && isActive === 't1'"
            >
              <h3>KYC has been successfully activated</h3>
            </div> -->
            <div class="col-lg-9" v-else-if="user && user.status === 3">
              {{ $t("DEACTIVATED") }}
            </div>
            <div class="col-lg-9" v-else-if="user && user.status === 4">
              {{ $t("COUNTRY_MISMATCH") }}
            </div>
            <div :class="'tabContent' + (isActive == 't2' ? ' active' : '')">
              <div
                class="w-100 d-flex align-items-center justify-content-center"
                v-if="bankInfoLoading"
              >
                <div class="loader"></div>
              </div>
              <form v-else class="financial-form ps-0 py-4">
                <h3 class="mt-4 text-center">{{ $t("BANK_DETIALS") }}</h3>
                <p class="my-4 text-center disclaimer">
                  {{ $t("BANK_DETAILS_TEXT") }}
                </p>
                <p class="validation-error w-100 text-center" :key="error" v-for="error of bankErrorMessages">
                  {{error.replace('eth', 'ETH').replace('btc', 'BTC')}}
                </p>
                <!-- <div class="form-group">
                  <label>{{ $t("IBAN") }}*</label>
                  <input
                    v-model="bankForm.iban"
                    class="customForm"
                    type="text"
                    :placeholder="$t('IBAN')"
                  />
                  <p
                    class="validation-error"
                    v-if="!bankForm.iban && bankValidationCalled"
                  >
                    {{ $t("PLEASE_ENTER_IBAN") }}
                  </p>
                  <p class="validation-error" v-if="invalidIban">
                    {{ $t("PLEASE_ENTER_VALID_IBAN") }}
                  </p>
                </div>
                <div class="form-group">
                  <label>{{ $t("BIC") }}*</label>
                  <input
                    v-model="bankForm.bic_code"
                    class="customForm"
                    type="text"
                    :placeholder="$t('BIC')"
                  />
                  <p
                    class="validation-error"
                    v-if="!bankForm.bic_code && bankValidationCalled"
                  >
                    {{$t('PLEASE_ENTER_BIC')}}
                  </p>
                  <p class="validation-error" v-if="invalidBic">
                    Please enter a valid BIC
                  </p>
                </div> -->
                <div class="form-group">
                  <label>{{$t('ETH_ADDRESS')}}*</label>
                  <input
                    v-model="bankForm.eth_address"
                    class="customForm"
                    type="text"
                    :placeholder="$t('ETH_ADDRESS')"
                  />
                  <p
                    class="validation-error"
                    v-if="!bankForm.eth_address && bankValidationCalled"
                  >
                    {{$t('PLEASE_ENTER_ETH_ADDRESS')}}
                  </p>
                  <p class="validation-error" v-if="invalidEthFormat">
                    {{$t('ETH_ADDRESS_INVALID')}}
                  </p>
                </div>
                <!-- <div class="form-group">
                  <label>{{$t('BTC_ADDRESS')}}*</label>
                  <input
                    v-model="bankForm.btc_address"
                    class="customForm"
                    type="text"
                    :placeholder="$t('BTC_ADDRESS')"
                  />
                  <p class="validation-error" v-if="invalidBtcFormat">{{$t('INVALID_BTC_FORMAT')}}</p>
                  <p
                    class="validation-error"
                    v-if="!bankForm.btc_address && bankValidationCalled"
                  >
                    {{$t('PLEASE_ENTER_BTC_ADDRESS')}}
                  </p>
                </div> -->

                <div class="form-group mt-4 pt-2">
                  <div
                    class="d-flex flex-column flex-lg-row align-items-center"
                  >
                    <button
                      type="button"
                      @click="updateBankInfo"
                      class="button w-100"
                    >
                      {{$t('UPDATE_BANK_INFO')}}
                    </button>
                    <!-- <div class="ms-0 ms-md-5 mt-4 mt-lg-0"><button class="button link blueDark">SEPA Lastschriftmandat <span class="chevronRight right"></span></button></div> -->
                  </div>
                  <!-- <p class="mt-3 bank-success" v-if="bankUpdateSuccess">Bank information updated successfully</p> -->
                </div>
              </form>
            </div>

            <div :class="'tabContent' + (isActive == 't3' ? 'active' : '')">
              <form @submit.prevent action="#">
                <div class="ps-0 py-4 password-form">
                  <h3 class="mt-4 text-center">{{$t('CHANGE_PASSWORD')}}</h3>
                  <p class="my-4 text-center disclaimer">
                    {{$t('PASSWORD_CHANGE_EXPLANATION')}}
                  </p>
                  <div class="form-group">
                    <label>{{$t('CURRENT_PASSWORD')}}</label>
                    <input
                      autocomplete="off"
                      class="customForm"
                      type="password"
                      name="current_password"
                      :placeholder="$t('ENTER_CURRENT_PASSWORD')"
                      v-model="current_password"
                    />
                  </div>
                  <div class="form-group">
                    <label>{{$t('NEW_PASSWORD')}}</label>
                    <input
                      autocomplete="off"
                      class="customForm"
                      type="password"
                      name="password"
                      :placeholder="$t('ENTER_NEW_PASSWORD')"
                      v-model="password"
                    />
                  </div>
                  <div class="form-group">
                    <label>{{$t('CONFIRM_NEW_PASSWORD')}}</label>
                    <input
                      autocomplete="off"
                      class="customForm"
                      type="password"
                      name="password_confirmation"
                      :placeholder="$t('CONFIRM_YOUR_NEW_PASSWORD')"
                      v-model="password_confirmation"
                    />
                    <p v-if="passwordConfirmNoMatch" class="validation-error">
                      {{$t('PASSWORD_CONFIRMATION_NO_MATCH')}}
                    </p>
                  </div>

                  <div class="form-group mt-4 pt-2">
                    <button @click="changePassword" class="button w-100">
                      {{$t('CHANGE_PASSWORD')}}
                    </button>
                    <button @click="logout" class="button white w-100 mt-3">
                      {{$t('SIGN_OUT')}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    @click="closeModal"
    :class="showDepositModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div
      :class="showDepositModal ? 'active' : ''"
      @click.stop
      v-if="showDepositModal"
      class="deposit-modal"
    >
      <div @click="closeModal" class="close-modal">✕</div>
      <h3 class="text-center">Initial Deposit</h3>
      <p class="text-center">
        Please make a Initial deposit of 100 euros to be able to start with KYC
        procedure. Nunc, tempus egestas fames cursus tincidunt pulvinar
        fermentum mi ultrices diam.
      </p>
      <router-link class="button w-100" to="/investments"
        >Make an Initial Deposit</router-link
      >
    </div>
  </div> -->
</template>

<script>
import { defineComponent } from "vue";
import config from "../../config";
import Datepicker from "vue3-datepicker";
import Subheader from "../../components/Subheader.vue";
// import { DropZone } from 'dropzone-vue';
import Dropzone from "dropzone";
import { ethers } from 'ethers';

// DropZone.autoDiscover = false;

// const userStatuses = {
//     1: 'inactive',
//     2: 'active',
//     3: 'deactivated',
//     4: 'mismatch'
// };

function isValidIBANNumber(input) {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  let iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);
}

function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export default defineComponent({
  name: "Settings",
  data: function () {
    return {
      invalidPhoneCode: false,
      bankErrorMessages: [],
      kycComment: null,
      submitSuccess: false,
      invalidPhone: false,
      firstLoad: true,
      dropzone1: null,
      dropzone2: null,
      dropzone3: null,
      hasKyc: null,
      passwordConfirmNoMatch: false,
      submitted: false,
      kycStatus: null,
      kycErrors: [],
      kycLoading: true,
      isActive: "t1",
      user: null,
      accessToken: null,
      showLoginModal: false,
      password: null,
      password_confirmation: null,
      iframeID: null,
      bankInfoLoading: true,
      bankUpdateSuccess: false,
      validationCalled: false,
      invalidIban: false,
      bankValidationCalled: false,
      hasDeposits: null,
      countries: null,
      current_password: null,
      hasCompletedKyc: null,
      showKycForm: true,
      profileData: null,
      loggedIn: null,
      showDepositModal: false,
      noNewPass: false,
      passwordNoMatch: false,
      noCurrentPass: false,
      invalidEthFormat: false,
      invalidBtcFormat: false,
      noEthAddress: false,
      noBtcAddress: false,
      bankForm: {
        eth_address: '',
      // btc_address: ''
        // iban: "",
        // bic_code: "",
      },
      kycForm: {
        firstname: "",
        lastname: "",
        email: "",
        birthday: new Date(),
        birth_place: "",
        // gender: "",
        city: "",
        country: "",
        nationality: "",
        street: "",
        zipcode: "",
        phone_code: "",
        mobilephone: "",
        documentFront: "",
        documentBack: "",
        selfieImage: "",
      },
    };
  },
  components: {
    //   Header,
    //   Footer
    // DropZone,
    Subheader,
    Datepicker,
  },
  methods: {
    handleImageUpload(type) {
      console.log(this.$refs[type].files);
      this.kycForm[type] = this.$refs[type].files[0];
    },
    onDocumentFrontRemove(event) {
      this.documentFront = event.file;
    },
    onDocumentBackRemove(event) {
      this.documentBack = event.file;
    },
    onSelfieRemove(event) {
      this.selfieImage = event.file;
    },
    onDocumentFrontAdd(event) {
      this.documentFront = event.file;
    },
    onDocumentBackAdd(event) {
      this.documentBack = event.file;
    },
    onSelfieAdd(event) {
      this.selfieImage = event.file;
    },
    handleKycBtnClick() {
      this.showKycForm = true;
    },
    async getUserProfile() {
      try {
        fetch(`${config.API}/api/v1/get-profile`, {
          headers: {
            ...config.defaultHeaders,
            Authorization: `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.data.profile.birthday);
            if (data?.data?.profile) {
              this.kycForm.firstname = data.data.profile.first_name;
              this.kycForm.lastname = data.data.profile.last_name;
              this.kycForm.birthday = data.data.profile.birthday
                ? new Date(data.data.profile.birthday)
                : new Date();
              this.kycForm.birth_place = data.data.profile.birth_place;
              // this.kycForm.gender = data.data.profile.gender
              //   ? data.data.profile.gender.toLowerCase()
              //   : null;
              this.kycForm.city = data.data.profile.city;
              this.kycForm.country = data.data.profile.country_id;
              this.kycForm.nationality = data.data.profile.country_id;
              this.kycForm.street = data.data.profile.address;
              this.kycForm.zipcode = data.data.profile.zip_code;
              this.kycForm.mobilephone = data.data.profile.phone;
              this.kycForm.phone_code = data.data.profile.phone_code
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async logout() {
      await window.localStorage.setItem("accessToken", "");
      await this.$store.dispatch("setLoggedIn", false);
      await this.$store.dispatch("setAccessToken", null);
      this.$router.push("/login");
    },
    async getBankInfo() {
      this.bankInfoLoading = true;
      try {
        const bankInfoRes = await fetch(
          `${config.API}/api/v1/get-financial-data`,
          {
            headers: {
              ...config.defaultHeaders,
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        ).then((res) => res.json());
        this.bankForm.eth_address = bankInfoRes.data.eth_address;
        this.bankForm.btc_address = bankInfoRes.data.btc_address;
        // this.bankForm.bank_name = bankInfoRes.data.bank_name;
        // this.bankForm.iban = bankInfoRes.data.iban;
        // this.bankForm.bic_code = bankInfoRes.data.bic_code;
        // this.bankForm.receiving_address = bankInfoRes.data.receiving_address;
        this.bankInfoLoading = false;
      } catch (error) {
        console.log(error);
        this.bankInfoLoading = false;
      }
    },
    selectTab(tab) {
      console.log(tab);
      this.isActive = tab;
    },
    getUserInfo() {
      this.kycLoading = true;
      fetch(`${config.API}/api/v1/get-user-info`, {
        headers: {
          ...config.defaultHeaders,
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (
            data?.data?.user?.status === 4 ||
            data?.data?.user?.status === 3
          ) {
            this.$store.dispatch("setCountryMistamtch", true);
            this.$router.push({
              name: "KYC Error",
              params: { status: data.data.user.status },
            });
          }
          this.user = data?.data?.user;
          this.kycForm.email = data?.data?.user?.email;
          this.kycLoading = false;
          this.hasKyc = data.data.user.has_confirmed_kyc;
        })
        .catch((error) => {
          console.log(error);
          this.kycLoading = false;
        });
    },
    getCountries() {
      fetch(`${config.API}/api/v1/countries`, {
        method: "GET",
        headers: {
          ...config.defaultHeaders,
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.countries = data.data.countries;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUser() {
      fetch(`${config.API}/api/v1/update-user`, {
        method: "POST",
        headers: {
          ...config.defaultHeaders,
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: this.user.name,
          last_name: this.user.last_name,
          address: this.user.address,
          city: this.user.city,
          post_code: this.user.post_code,
          birth_place: this.user.birth_place,
          country_id: this.user.country_id,
          phone: this.user.phone,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors && data.errors.length) {
            this.submitted = false;
            this.handleErrors(data.errors);
          } else {
            this.password = null;
            this.password_confirmation = null;
            this.$toast.success(this.$t('PROFILE_DATA_UPDATED'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePassword() {
      let valid = true;
      if (!this.current_password) {
        this.noCurrentPass = true;
        valid = false;
      } else {
        this.noCurrentPass = false;
      }
      if (!this.current_password) {
        this.noNewPass = true;
        valid = false;
      } else {
        this.noNewPass = false;
      }
      if (this.password_confirmation !== this.password) {
        this.passwordNoMatch = true;
        valid = false;
      } else {
        this.passwordNoMatch = false;
      }
      if (!valid) {
        return;
      }
      fetch(`${config.API}/api/v1/change-password`, {
        method: "POST",
        headers: {
          ...config.defaultHeaders,
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors && data.errors.length) {
            this.submitted = false;
            this.handleErrors(data.errors);
          } else {
            this.password = null;
            this.password_confirmation = null;
            this.current_password = null;
            this.$toast.success(this.$t('PASSWORD_CHANGE_SUCCESS'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async updateBankInfo() {
      let validationErrors = 0;
      // this.bankValidationCalled = true;
      // if (
      //   // !this.bankForm.iban ||
      //   // !this.bankForm.bic_code ||
      //   !this.bankForm.eth_address ||
      //   !this.bankForm.btc_address
      // ) {
      //   return;
      // }

      if (!this.bankForm.eth_address) {
        this.noEthAddress = true;
        validationErrors++;
      } else {
        try {
          ethers.utils.getAddress(this.bankForm.eth_address);
          this.invalidEthFormat = false;
        } catch(err) {
          this.invalidEthFormat = true;
          validationErrors++;
        }
        this.noEthAddress = false;
      }

      if (!this.bankForm.btc_address) {
        this.noBtcAddress = true;
      } else {
        this.noBtcAddress = false;
      }

      if (this.bankForm.btc_address && !this.bankForm.btc_address.match(/^(bc1|[123])[a-zA-HJ-NP-Z0-9]{25,39}$/g)) {
        this.invalidBtcFormat = true;
        validationErrors++;
      } else {
        this.invalidBtcFormat = false;
      }

      // if (!isValidIBANNumber(this.bankForm.iban)) {
      //   this.invalidIban = true;
      //   return;
      // } else {
      //   this.invalidIban = false;
      // }

      if (validationErrors > 0) {
        return;
      }

      this.bankInfoLoading = true;
      let reqBody = { ...this.bankForm };
      try {
        this.bankErrorMessages = [];
        const bankRes = await fetch(
          `${config.API}/api/v1/update-financial-data`,
          {
            method: "POST",
            headers: {
                            ...config.defaultHeaders,
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(reqBody),
          }
        ).then((res) => res.json());
        console.log(bankRes);
        if (bankRes.errors?.length) {
          this.bankErrorMessages = bankRes.errors;
          this.getBankInfo();
          this.bankInfoLoading = false;
          return;
        }
        this.$toast.success(this.$t('BANK_INFO_UPDATED'));
        this.bankInfoLoading = false;
      } catch (error) {
        console.log(error);
        this.bankInfoLoading = false;
      }
    },
    handleErrors(errors) {
      var errorStr = "";
      if (errors.length) {
        errors.forEach((value) => {
          errorStr = errorStr + value + "<br>";
        });
      }
      this.$toast.error(errorStr);
    },
    closeModal() {
      this.showLoginModal = false;
      this.showDepositModal = false;
    },
    async submitKyc() {
      let validationStatus = true;
      this.kycErrors = [];
      this.validationCalled = true;
      const formData = new FormData();
      if (!this.kycForm.documentFront || !this.kycForm.documentFront.type.split('/')[1].match(/png|jpg|jpeg/)) {
        validationStatus = false;
      }
      if (!this.kycForm.documentBack || !this.kycForm.documentBack.type.split('/')[1].match(/png|jpg|jpeg/)) {
        validationStatus = false;
      }
      if (!this.kycForm.selfieImage || !this.kycForm.selfieImage.type.split('/')[1].match(/png|jpg|jpeg/)) {
        validationStatus = false;
      }
      if (this.kycForm.mobilephone.length && !this.kycForm.mobilephone.match(/^\+{0,1}[0-9]*$/gi)) {
        this.invalidPhone = true;
        validationStatus = false;
      } else {
        this.invalidPhone = false;
      }
      console.log('phone codeeeee', this.kycForm.phone_code);
      if (this.kycForm.phone_code.length && !this.kycForm.phone_code.match(/^\+{0,1}[0-9]*$/gi)) {
        this.invalidPhoneCode = true;
        validationStatus = false;
      } else {
        this.invalidPhoneCode = false;
      }
      for (const key in this.kycForm) {
        if (!this.kycForm[key]) {
          console.log('keyyyy', key);
          return;
        }
      }
      if (!validationStatus) {
        return;
      }
      formData.append("first_name", this.kycForm.firstname);
      formData.append("last_name", this.kycForm.lastname);
      formData.append("country_id", this.kycForm.country);
      formData.append("city", this.kycForm.city);
      formData.append("address", this.kycForm.street);
      formData.append("zip_code", this.kycForm.zipcode);
      formData.append("phone_code", this.kycForm.phone_code);
      formData.append("phone", this.kycForm.mobilephone);
      formData.append("selfie_image", this.kycForm.selfieImage);
      formData.append("document_front_image", this.kycForm.documentFront);
      formData.append("document_back_image", this.kycForm.documentBack);
      formData.append('birth_place', this.kycForm.birth_place);
      formData.append('birthday', this.kycForm.birthday.toString().slice(0, 33));
      try {
        this.kycLoading = true;
        const kycRes = await fetch(this.kycStatus === 4 ? `${config.API}/api/v1/update-kyc-request` : `${config.API}/api/v1/kyc-request`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            Accept: "application/json",
            'Accept-Language': window.localStorage.getItem('locale')?.replace('-', '_') || process.env.VUE_APP_I18N_LOCALE?.replace('-', '_') || 'en_US',
          },
          body: formData,
        }).then((res) => res.json());
        console.log(kycRes);
        if (kycRes.errors?.length) {
          this.kycErrors = kycRes.errors;
          this.setupDropzone();
        } else {
          this.submitSuccess = true;
          this.$toast.success(this.$t('KYC_SUBMIT_SUCCESS'));
        }
        this.kycLoading = false;
      } catch (error) {
        console.log(error);
        this.kycLoading = false;
      }
    },
    setupDropzone() {
      const dropzoneOptionsImage = {
        paramName: "file",
        maxFilesize: 8,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: this.$t('DROP_FILES_OR_CLICK'),
        maxFiles: 1,
        init: function() {
          this.on('addedfile', function(file) {
            if (!file.type.match('image')) {
              this.removeFile(this.files[this.files.length - 1]);
            }
          });
          this.on('maxfilesexceeded', function(file) {
            this.removeAllFiles();
            this.addFile(file);
          });
        }
      };
      setTimeout(() => {
        if (this.kycStatus !== null && this.kycStatus !== 4) {
          return;
        }
        const dropzone1 = new Dropzone("#dropzone-1", dropzoneOptionsImage);
        const dropzone2 = new Dropzone("#dropzone-2", dropzoneOptionsImage);
        const dropzone3 = new Dropzone("#dropzone-3", dropzoneOptionsImage);
        dropzone1.on('addedfile', file => {
          this.kycForm.documentFront = file;
        });
        dropzone2.on('addedfile', file => {
          this.kycForm.documentBack = file;
        });
        dropzone3.on('addedfile', file => {
          this.kycForm.selfieImage = file;
        });
        dropzone1.on('removedfile', () => {
          this.kycForm.documentFront = '';
        });
        dropzone2.on('removedfile', () => {
          this.kycForm.documentBack = '';
        });
        dropzone3.on('removedfile', () => {
          this.kycForm.selfieImage = '';
        });
        // dropzone1.on('maxfilesexceeded', function(file) {
        //   this.removeAllFiles(this.files[0]);
        //   this.addFile(file);
        // });
      }, 1000);
    }
  },
  mounted() {
    Dropzone.autoDiscover = false;
    this.setupDropzone();
    this.hasDeposits = this.$store.state.hasMinDeposit;
    this.hasCompletedKyc = this.$store.state.userData?.has_confirmed_kyc;
    this.loggedIn = this.$store.state.loggedIn;
    this.$store.watch((state) => {
      this.kycStatus = state.kycStatus;
      this.accessToken = state.accessToken;
      this.hasDeposits = state.hasMinDeposit;
      this.hasCompletedKyc = state.userData?.has_confirmed_kyc;
      this.loggedIn = state.loggedIn;
      this.kycComment = state.kycComment;
      if (state.accessToken && this.firstLoad) {
        this.firstLoad = false;
        this.getUserInfo();
        this.getUserProfile();
        this.getBankInfo();
        this.getCountries();
      }
    });
  },
  watch: {
    'kycForm.mobilephone': function(val) {
      this.invalidPhone = false;
    }
  }
});
</script>
